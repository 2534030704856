const alert = {
    methods:{
        swal(message,type = "info", time = 5000 ){
           this.$swal({
                    position: 'top',
                    toast: true,
                    type: type,
                    title: message,
                    showConfirmButton: false,
                    timer: time
                    })
            return "HOLA"
        }
    }
}

export default alert;