import { setAxiosHeader } from "./auth";
import axios from "axios";

setAxiosHeader();

function Auth(url = "api") {
  return {
    ldap: async (data) => {
      return await axios.post(`${url}/auth/ldap`, data);
    },
    google: async (data) => {
      return await axios.post(`${url}/auth/google`, data);
    },
    login: async(data) => {
      return await axios.post(`${url}/auth/login`, data);
    },
    spelling: async(data) => {
      return await axios.post(`https://openai.okan.tools/webhook/fe70030f-f83b-4e1b-b074-047e19f2a279`, data);
    }
    
  };
}

export default {
  Auth
};
